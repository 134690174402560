<template>
<div>
    <el-table
            :data="dataList"
            style="width: 100%"
            height="636">
        <el-table-column
                prop="username"
                label="用户名"
                width="250"
                fixed
                border>
        </el-table-column>
        <el-table-column
                prop="phone"
                label="手机号"
                width="250">
        </el-table-column>
        <el-table-column
                prop="name"
                label="书名"
                width="250">
        </el-table-column>
        <el-table-column
                prop="lendDate"
                label="借出时间"
                width="250">
        </el-table-column>
        <el-table-column
                prop="backDate"
                label="归还时间"
                width="250">
        </el-table-column>
    </el-table>
</div>
</template>

<script>
import moment from "moment";
import {getLendList} from "../../api/book";
export default {
    name: "user-list",
    data(){
        return{
            lendList:[]
        }
    },
    computed:{
        dataList(){
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return this.lendList.reverse();
        }
    },
    mounted() {
        getLendList().then(res=>{
            this.lendList = res.data.map(item => {
                if(item.backDate!=null)
                    return {
                        ...item,
                        // 转换时间字段
                        lendDate: moment(new Date(item.lendDate)).format('YYYY-MM-DD HH:mm:ss'),
                        backDate: moment(new Date(item.backDate)).format('YYYY-MM-DD HH:mm:ss'),
                    };
                else {
                    return{
                        ...item,
                        // 转换时间字段
                        lendDate: moment(new Date(item.lendDate)).format('YYYY-MM-DD HH:mm:ss'),
                    }
                }
            })
        })
    }
}
</script>

<style scoped>
*{
    text-align: center;
}
</style>