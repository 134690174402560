const bookClassification = [
    { index: 0, classNumber: " ", className: "全部" },
    { index: 1, classNumber: "A", className: "马克思主义、列宁主义、毛泽东思想、邓小平理论" },
    { index: 2, classNumber: "B", className: "哲学、宗教" },
    { index: 3, classNumber: "C", className: "社会科学总论" },
    { index: 4, classNumber: "D", className: "政治、法律" },
    { index: 5, classNumber: "E", className: "军事" },
    { index: 6, classNumber: "F", className: "经济" },
    { index: 7, classNumber: "G", className: "文化、科学、教育、体育" },
    { index: 8, classNumber: "H", className: "语言、文字" },
    { index: 9, classNumber: "I", className: "文学" },
    { index: 10, classNumber: "J", className: "艺术" },
    { index: 11, classNumber: "K", className: "历史、地理" },
    { index: 12, classNumber: "N", className: "自然科学总论" },
    { index: 13, classNumber: "O", className: "数理科学和化学" },
    { index: 14, classNumber: "P", className: "天文学、地球科学" },
    { index: 15, classNumber: "Q", className: "生物科学" },
    { index: 16, classNumber: "R", className: "医药、卫生" },
    { index: 17, classNumber: "S", className: "农业科学" },
    { index: 18, classNumber: "T", className: "工业技术" },
    { index: 19, classNumber: "U", className: "交通运输" },
    { index: 20, classNumber: "V", className: "航空、航天" },
    { index: 21, classNumber: "X", className: "环境科学、安全科学" },
    { index: 22, classNumber: "Z", className: "综合性图书" },
    { index: 23, classNumber: "", className: "其他书籍" }
];

// 导出对象以便在其他文件中使用
export default bookClassification;